<template>
  <div class="content md no-padding flex-box vertical">
    <MemberForm ref="addForm" :initial="initial" class="add-form-box"></MemberForm>
    <a-space class="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleConfirm">保存</a-button>
    </a-space>
  </div>
</template>

<script>
import { randomString } from '../../../common/js/tool';
import MemberForm from "./components/MemberForm.vue";

export default {
  name: "MemberAdd",
  components: {
    MemberForm,
  },
  data() {
    return {
      initial: {
        calendar: 1,
        professionalGroup: [{key: randomString(16)}],
      },
    };
  },
  methods: {
    handleCancel() {
      this.$router.back();
    },
    handleConfirm() {
      this.$refs.addForm.validate().then((form) => {
        this.$axios({
          url: "/admin/volunteer",
          method: "POST",
          data: form,
        }).then(() => {
          this.$message.success("添加成功");
          this.$router.back();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
}
</style>
